/**
 * Simple validation functions.
 */

/**
 * Return whether or not passed email is valid.
 *
 * Basic email regex pattern from: https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
 *
 * @param {String} email Email to check for validity
 *
 * @return {Boolean} Returns validity of passed email
 */
export function isValidEmail(email) {
  if (!email) {
    return false;
  }

  // First validate against standard regex used by HTML Email input type
  const standardRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  // And also make sure email has a domain. This isn't actually required by the
  // expression above because local intranet email addresses are valid, but
  // we're not expecting signups from domain-less email addresses.
  const domainCheckRegex = /.+@.+\..+/;

  return standardRegex.test(email) && domainCheckRegex.test(email);
}

/**
 * Return whether or not passed password meets password standard.  These are not
 * exactly the most stringent password standards.  Should probably review that
 * at some point.
 *
 * @param {String} password Password to validate
 *
 * @return {Boolean} Returns true/false status of password
 */
export function isValidPassword(password) {
  return !!password && password.length > 9;
}

/**
 * Return whether or not passed zipCode is valid. We don't explicitly ask for a
 * user's country so outside of that all you can really validate against for
 * most countries is "letters, numbers, hyphens or spaces, up to 9 characters
 * long."
 *
 * @param {String} zip code
 * @param {String} country code
 *
 * @return {Boolean} Returns validity of passed zipCode
 */
export function isValidZipCode(zipCode, countryCode) {
  let zipCodeRegex;

  switch (countryCode) {
    case 'US':
      zipCodeRegex = /^[a-zA-Z0-9\s-]{0,9}$/;
      break;
    case 'AU':
      zipCodeRegex = /^\d{4}$/;
      break;
    case 'DE':
      zipCodeRegex = /^\d{5}$/;
      break;
    case 'GB':
      zipCodeRegex =
        /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
      break;
    // Postal/Zip code is not required for Hong Kong because it doesn't have them
    case 'HK':
      return true;
    default:
      zipCodeRegex = /^(?:[A-Z0-9]{0,16}[-\s]?([A-Z0-9]{0,16}))?$/;
  }

  return !!zipCode && zipCodeRegex.test(zipCode);
}
