import styled, { css } from 'styled-components';

export const Spacer = styled.div`
  width: 100%;
  margin-top: 3rem;

  ${({ $xxs }) => $xxs && css`
    margin-top: 0.5rem;
  `}

  ${({ $xs }) => $xs && css`
    margin-top: 1rem;
  `}

  ${({ $s }) => $s && css`
    margin-top: 2rem;
  `}

  ${({ $m }) => $m && css`
    margin-top: 3rem;
  `}

  ${({ $l }) => $l && css`
    margin-top: 4rem;
  `}

  ${({ $xl }) => $xl && css`
    margin-top: 5rem;
  `}
`;
