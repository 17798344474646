import styled from 'styled-components';

import { commonSpacing } from '@/styles/js/utils/spacing';

import { ActionSet as StyledActionSet } from '@/components/forms/ActionSet/index.styles';

export const SignupForm = styled.form`
  ${commonSpacing}

  margin: 0 2rem 0;

  & > ${StyledActionSet} {
    padding-right: 0;
    padding-left: 0;
  }
`;
